<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增产品"
      :width="1000"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm">
        <a-row>
          <a-col :span="12">
            <a-form-item label="产品名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { max: 64, message: '超出最大长度(64)' },
                      { required: true, message: '请输入产品名称' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="产品编号" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input
                v-decorator="[
                  'number',
                  {
                    rules: [
                      { max: 32, message: '超出最大长度(32)' },
                      { required: true, message: '请输入产品编号' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="条形码" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['barcode', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]" :allowClear="true" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="分类" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-decorator="['category']" style="width: 100%" :allowClear="true">
                <a-select-option v-for="item of categoryItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="单位" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-decorator="['unit']" :allowClear="true">
                <a-select-option v-for="item of unitItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="规格" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input
                v-decorator="['spec', { rules: [{ max: 64, message: '超出最大长度(64)' }], initialValue: '' }]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="尺寸" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input
                v-decorator="['drawing_number', { rules: [{ max: 64, message: '超出最大长度(64)' }] }]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12">
            <a-form-item label="来源" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-select v-decorator="['source', { initialValue: [] }]" mode="tags" style="width: 100%">
                <a-select-option value="采购">采购</a-select-option>
                <a-select-option value="自产">自产</a-select-option>
                <a-select-option value="外协加工">外协加工</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-input v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="同步子产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
              <a-switch v-model="isSync" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="BOM清单" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <BOMComponentTable v-decorator="['bom_component_items', { initialValue: [] }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { goodsInformationCreate, bomDetailCreate } from "@/api/goods";
import { goodsClassificationOption, goodsUnitOption } from "@/api/option";
import { getGoodsNumber } from "@/api/data";
import { bomDetailList } from "@/api/goods";

export default {
  components: {
    BOMComponentTable: () => import("@/components/BOMComponentTable"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,

      categoryItems: [],
      unitItems: [],
      isSync: false,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields(async (error, values) => {
        if (error === null) {
          try {
            this.confirmLoading = true;
            const goodsItem = await goodsInformationCreate({
              barcode: values.barcode,
              number: values.number,
              name: values.name,
              category: values.category,
              unit: values.unit,
              spec: values.spec,
              drawing_number: values.drawing_number,
              source: values.source,
              remark: values.remark,
            });

            if (values.bom_component_items.length > 0) {
              await bomDetailCreate({
                goods: goodsItem.id,
                remark: values.remark,
                bom_component_items: values.bom_component_items,
                is_sync: this.isSync,
              });
            }

            this.$emit("create", goodsItem);
            this.$message.success("新增成功");
            this.handleCancel();
          } finally {
            this.confirmLoading = false;
          }
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(status) {
      if (status) {
        goodsClassificationOption({ page_size: 999999, is_active: true }).then((resp) => {
          this.categoryItems = resp.results;
        });

        goodsUnitOption({ page_size: 999999, is_active: true }).then((resp) => {
          this.unitItems = resp.results;
        });

        getGoodsNumber().then((data) => {
          this.dataForm.setFieldsValue({ number: data.number });
        });

        if (this.item) {
          bomDetailList({ goods: this.item.id }).then((data) => {
            this.dataForm.setFieldsValue({
              barcode: this.item.barcode,
              name: this.item.name,
              category: this.item.category,
              unit: this.item.unit,
              spec: this.item.spec,
              drawing_number: this.item.drawing_number,
              source: this.item.source,
              remark: this.item.remark,
            });

            if (data.results.length > 0) {
              this.dataForm.setFieldsValue({
                bom_component_items: data.results[0]["bom_component_items"],
              });
            }
          });
        }
      }
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
